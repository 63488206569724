import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import checkAuth from "./system/auth/authenticate.js";
Vue.use(Router);

// create new router

const routes = [
  {
    path: "/",
    component: () => import("./views"),
    redirect: "/homepage",
    beforeEnter: checkAuth,
    children: [
      {
        path: "/homepage",
        component: () => import("./views/home/homePage"),
      },
      {
        path: "/profit",
        component: () => import("./views/profit/profit"),
      },
      {
        path: "/stake",
        component: () => import("./views/stake/stake"),
      },
      {
        path: "/nature",
        component: () => import("./views/nature/nature"),
      },
      {
        path: "/mePage",
        component: () => import("./views/settings/mePage"),
      },

    ]
  },
  {
    path: "/main",
    component: () => import("./views/node/main"),
  },
  {
    path: "/newsList",
    component: () => import("./views/settings/news/newsList")
  },
  
  {
    path: "/newsDetails",
    name: "newDetails",
    props: true,
    component: () => import("./views/settings/news/newsDetails"),
  },
  {
    path: "/partner",
    component: () => import("./views/settings/partnerCenter/partner"),
  },
  {
    path: "/partnerRecord",
    component: () => import("./views/settings/partnerCenter/partnerRecord"),
  },
  {
    path: "/partnerDetail",
    component: () => import("./views/settings/partnerCenter/partnerDetail"),
  },
  {
    path: "/stakeDetail",
    component: () => import("./views/stake/stakeDetail"),
  },
  {
    path: "/promoDetail",
    component: () => import("./views/stake/promoDetail"),
  },
  {
    path: "/stakeWithdraw",
    component: () => import("./views/stake/stakeWithdraw"),
  },
  {
    path: "/stakeInfo",
    component: () => import("./views/stake/stakeInfo"),
  },
  {
    path: "/tutorial",
    component: () => import("./views/sessions/firstTime")
  },
  {
    path: "/metamask",
    component: () => import("./views/metamask")
  },
  {
    path: "/setting",
    beforeEnter: checkAuth,
    component: () => import("./views/settings/setting")
  },
  {
    path: "/help",
    beforeEnter: checkAuth,
    component: () => import("./views/settings/helpCenter/help")
  },
  {
    path: "/reset-pwd",
    beforeEnter: checkAuth,
    component: () => import("./views/settings/reset-pwd")
  },
  {
    path: "/sales",
    beforeEnter: checkAuth,
    component: () => import("./views/profit/sales")
  },
  {
    path: "/threeSales",
    beforeEnter: checkAuth,
    component: () => import("./views/profit/threeSales")
  },
  {
    path: "/transfer",
    beforeEnter: checkAuth,
    component: () => import("./views/profit/internalTransfer")
  },
  {
    path: "/dynamic_wallet",
    beforeEnter: checkAuth,
    component: () => import("./views/profit/dynamic_wallet")
  },
  {
    path: "/memberTree",
    beforeEnter: checkAuth,
    component: () => import("./views/profit/tree/treeRoot")
  },
  {
    path: "/performance",
    beforeEnter: checkAuth,
    component: () => import("./views/profit/performance")
  },
  {
    path: "/team",
    beforeEnter: checkAuth,
    component: () => import("./views/settings/team")
  },
  {
    path: "/deposit",
    beforeEnter: checkAuth,
    component: () => import("./views/deposit/deposit")
  },
  {
    path: "/USDTwithdraw",
    beforeEnter: checkAuth,
    component: () => import("./views/withdraw/USDTwithdraw")
  },
  {
    path: "/withdraw",
    beforeEnter: checkAuth,
    component: () => import("./views/withdraw/withdraw")
  },
  {
    path: "/transfer",
    beforeEnter: checkAuth,
    component: () => import("./views/withdraw/transfer")
  },
  {
    path: "/record",
    beforeEnter: checkAuth,
    component: () => import("./views/settings/record")
  },
  {
    path: "/stakingSales",
    beforeEnter: checkAuth,
    component: () => import("./views/settings/threeSales")
  },
  {
    path: "/backfill",
    beforeEnter: checkAuth,
    component: () => import("./views/node/backfill/backfill")
  },
  {
    path: "/backfillAction",
    beforeEnter: checkAuth,
    component: () => import("./views/node/backfill/backfillAction")
  },
  {
    path: "/backfillRecord",
    beforeEnter: checkAuth,
    component: () => import("./views/node/backfill/backfillRecord")
  },
  {
    path: "/nodeRecord",
    beforeEnter: checkAuth,
    component: () => import("./views/node/nodeRecord")
  },
  {
    path: "/purchaseAIB",
    beforeEnter: checkAuth,
    component: () => import("./views/settings/purchaseCenter/purchaseAIB")
  },
  {
    path: "/invite",
    beforeEnter: checkAuth,
    component: () => import("./views/settings/inviteCenter"),
    children: [
      {
        path: "/inviteCode",
        beforeEnter: checkAuth,
        component: () => import("./views/settings/inviteCenter/inviteCode"),
      },
      {
        path: "/inviteCodeRecord",
        beforeEnter: checkAuth,
        component: () => import("./views/settings/inviteCenter/inviteCodeRecord"),
      },
      {
        path: "/inviteCenter",
        beforeEnter: checkAuth,
        component: () => import("./views/settings/inviteCenter/inviteCenter"),
      },
      {
        path: "/invitePage",
        beforeEnter: checkAuth,
        component: () => import("./views/settings/inviteCenter/invitePage"),
      }

    ]
  },
  {
    path: "/login",
    beforeEnter: async (to, from, next) => {
      console.log(to.fullPath.split('/login?')[1]);
      var startTime = localStorage.getItem("firstTime") || '0';
      if (parseInt(startTime) == 0) {
        await localStorage.setItem('firstTime', '1');
        next('/tutorial?page=1&' + to.fullPath.split('/login?')[1]);
      } else {
        next();
      }

    },
    component: () => import("./views/sessions/loginPage")
  },
  {
    path: "*",
    component: () => import("./views/pages/notFound")
  }
];

const router = new Router({
  mode: "history",
  linkActiveClass: "open",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.

    NProgress.start();
    NProgress.set(0.1);
  }
  next();
});

router.afterEach(() => {
  // Remove initial loading
  const gullPreLoading = document.getElementById("loading_wrap");
  if (gullPreLoading) {
    gullPreLoading.style.display = "none";
  }
  setTimeout(() => NProgress.done(), 100);
});

export default router;
