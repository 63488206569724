import request from '../request';
import config from '../config';

var current = config.url;
// var current = location.origin + '/';
export var tokenInfo = {
	//testnet
	// 'token': '0x1dc6723689baCc114A3698b695d6D8E6c2c66182',
	// 'pool': '0xdbbd9fDb6ae7F39D64163735e25dA9270c9F30FD'
	'token': '0x3702117BB800dee5b833f72180C4310392Cdd6b9',
	'pool': '0x1c1c72E692Ba3BAe83309E3b8e59414EFb01dE31'
}

function retHeader() {
	const header = {
		'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json',
		'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',
		'Authorization': 'Bearer' + localStorage.getItem("aibotToken"),
	};
	return header;

}

export function getUserNewsList(language,news_type,page) {
	const data = request({
		url: current + 'api/news/news-list?language=' + language  + '&news_type=' + news_type+ '&page=' + page,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function withdraw(postData) {
	const data = request({
		url: current + 'api/wallet/withdraw',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function get_ticker() {
	const data = request({
		url: current + 'api/project/aibOpenApi',
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getUserBonusRecord(postData, page) {
	const data = request({
		url: current + 'api/record/bonus-record?bonus=' + postData + '&page=' + page,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getUserPackage(params) {
	const data = request({
		url: current + 'api/package/get-user-package'+params,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getAibInfo() {
	const data = request({
		url: current + 'api/member/getAibInfo',
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function homepage() {
	const data = request({
		url: current + 'api/member/home-page',
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getStakePackage() {
	const data = request({
		url: current + 'api/staking/getPackageType',
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function stakingRecordInfo(params) {
	const data = request({
		url: current + 'api/staking/stakingRecordInfo'+params,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getStakingRecord(params) {
	const data = request({
		url: current + 'api/staking/getStakingRecord'+params,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function claimRankAib(postData) {
	const data = request({
		url: current + 'api/member/claimRankAib',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function changeStakingPackage(postData) {
	const data = request({
		url: current + 'api/staking/changeStakingPackage',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function staking(postData) {
	const data = request({
		url: current + 'api/staking/staking',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function terminateStaking(postData) {
	const data = request({
		url: current + 'api/staking/terminateStaking',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getSupportFundInfo() {
	const data = request({
		url: current + 'api/project/getSupportFundInfo',
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function claimSupportFund(postData) {
	const data = request({
		url: current + 'api/project/claimSupportFund',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function upgradeRank(postData) {
	const data = request({
		url: current + 'api/member/upgradeRank',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getLinxRecord(params) {
	const data = request({
		url: current + 'api/project/getLinxRecord'+params,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getDinvestRecord(params) {
	const data = request({
		url: current + 'api/project/getDinvestRecord'+params,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function purchaseLinx(postData) {
	const data = request({
		url: current + 'api/project/purchaseLinx',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function purchaseDinvest(postData) {
	const data = request({
		url: current + 'api/project/purchaseDinvest',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function totalIncome() {
	const data = request({
		url: current + 'api/member/totalIncome',
		method: 'get',
		// data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function memberInfo(postData) {
	const data = request({
		url: current + 'api/member/get-member-info',
		method: 'get',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function checkRefAddress(postData) {
	const data = request({
		url: current + 'api/auth/checkRefAddress',
		method: 'post',
		data: postData,
		// headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getGlobalSetting() {
	const data = request({
		url: current + 'api/global/lookup',
		method: 'get',
		// headers: retHeader()
	});

	return Promise.resolve(data);
}

export function depositRecord(params) {
	const data = request({
		url: current + 'api/wallet/boDepositRecord'+params,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function changeWallet(postData) {
	const data = request({
		url: current + 'api/wallet/changeWallet',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function walletTransfer(postData) {
	const data = request({
		url: current + 'api/wallet/wallet-transafer',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function tansferRecord(params) {
	const data = request({
		url: current + 'api/wallet/wallet-tranfer-record'+params,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function purchaseAib(postData) {
	const data = request({
		url: current + 'api/project/purchaseAib',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function withdrawRecord(params) {
	const data = request({
		url: current + 'api/wallet/withdraw-record'+params,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getTeamList(id) {
	const data = request({
		url: current + 'api/team/downline-new?parent='+id,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function organize(id) {
	const data = request({
		url: current + 'api/team/findNodeAddress?parent='+id,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function generateGoogle2FAKey() {
	const data = request({
		url: current + 'api/member/generateGoogle2FAKey',
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function teamByAddress(name) {
	const data = request({
		url: current + 'api/team/findNodeAddress?search='+name,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getPackage(postData) {
	const data = request({
		url: current + 'api/package/get-package',
		method: 'get',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getStakingSales() {
	const data = request({
		url: current + 'api/member/getStakingSales',
		method: 'get',
		// data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function authLogin(postData) {
	const data = request({
		url: current + 'api/auth/walletLogin',
		method: 'post',
		data: postData,
		// headers: retHeader()
	});

	return Promise.resolve(data);
}

export function upgradePackage(postData) {
	const data = request({
		url: current + 'api/package/upgrade-package',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function changeSecPassword(postData) {
	const data = request({
		url: current + 'api/member/change-secpassword',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function validateGoogle(postData) {
	const data = request({
		url: current + 'api/member/validateGoogle',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function setSecPwd(postData, token) {
	const data = request({
		url: current + 'api/member/set-secpassword',
		method: 'post',
		data: postData,
		headers: {
			'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',
			'Authorization': 'Bearer' + token,
		}
	});

	return Promise.resolve(data);
}

export function getUserWalletRecord(page) {
	const data = request({
		url: current + 'api/record/wallet-record' + page,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getRankInfo() {
	const data = request({
		url: current + 'api/member/getRankInfo',
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

