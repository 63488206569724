import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

var DEFAULT_LANG = "en";


const usersLanguage = window.navigator.language;
console.log(navigator.language);
var savedLan = localStorage.getItem('lan');
if (usersLanguage.includes('zh')) {
  DEFAULT_LANG = 'zh';
} else {
  DEFAULT_LANG = 'en';
}

if (savedLan) {
  DEFAULT_LANG = savedLan;

}


const locales = {
  en: require("./i18n/en.json"),
  zh: require("./i18n/zh.json"),
  fn: require("./i18n/fn.json"),
  in: require("./i18n/in.json"),
  jp: require("./i18n/jp.json"),
  kr: require("./i18n/kr.json"),
  sp: require("./i18n/sp.json"),
  th: require("./i18n/th.json"),
  vn: require("./i18n/vn.json"),
};
const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: locales
});

export default i18n;
