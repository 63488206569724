import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./assets/styles/sass/global.scss";
import i18n from "./system/lang/lang.js";
import { createPinia } from "pinia";
import VueQRCodeComponent from 'vue-qrcode-component';
Vue.component('qr-code', VueQRCodeComponent);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(createPinia())

Vue.config.productionTip = false

import { getGlobalSetting, homepage, totalIncome, getUserNewsList } from "@/system/request/api/api";
import { handleError } from "@/system/request/handleRes";

new Vue({
  data() {
    return {
      isLoading: false,
      userInfo: {
        fee_cap: 0,
        google_lock:0,
      },
      teamList: [],
      packageList: [],
      currentAddress: '',
      msg: '',
      subtitle: '',
      variant: '',
      type: '',
      aibPrice: 0,
      assetInfo: {
        big: "0.00",
        dynamic_bonus: "0.00",
        notice: 0,
        small: 0,
        special_bonus: "0.00",
        static_bonus: "0.00",
        team_bonus: "0.00",
        total_asset: "300.00",
        total_asset_currency: "MYR 1230.00",
        total_income: "0.00",
      },
      totalIncome: {},
      promo_start: '',
      promo_end: '',
      newsList: [],
      currentPage: 1,
      params: {
        language: '',
        page: 1,

      }
    }
  },
  methods: {
    goNewsDetails(item) {
      this.$bvModal.hide('modal-countdown');
      this.$router.push({
        name: 'newDetails',
        params: {
          item: item
        }
      });
    },
    getNews() {
      // var self = this;
      // let params = '?';
      // for (const key in self.pageParams) {

      //   if (key == 'language') {
      //     params = `${params}${key}=${this.$i18n.locale}`;

      //   } else {
      //     params = `${params}${key}=${self.pageParams[key]}`;
      //   }
      //   if (key != 'size') {
      //     params = params + '&';

      //   }
      // }
      this.isLoading = true;
      var result = getUserNewsList(this.$i18n.locale == 'zh' ? 'cn' : this.$i18n.locale, 1, this.currentPage);
      var self = this;
      this.isLoading = true;
      result
        .then(function (value) {

          var dataList = value.data.data.record.data;
          self.currentPage += 1;
          for (let i = 0; i < dataList.length; i++) {
            self.newsList.push(dataList[i]);
          }
          self.isLoading = false;
          if (self.newsList.length > 0) {
            self.$bvModal.show('modal-countdown');
          }
        })
        .catch(function () {
          self.isLoading = false;
        });

    },
    totalIncomeF() {
      var self = this;
      var result = totalIncome();
      result.then(function (value) {
        self.totalIncome = value.data.data;


      }).catch(function (error) {
        self.makeToast("danger", self.$t(handleError(error)), 'danger');
      });
    },
    _homepage() {
      var self = this;
      var result = homepage();
      result.then(function (value) {
        self.assetInfo = value.data.data;

        localStorage.setItem('assetInfo', JSON.stringify(self.assetInfo))

      }).catch(function (error) {
        self.makeToast("danger", self.$t(handleError(error)), 'danger');
      });
    },
    getGlobalInfo() {
      var self = this;
      var result = getGlobalSetting();
      result.then(function (value) {
        self.aibPrice = value.data.data.system.AIB_PRICE;
        self.promo_start = value.data.data.system.promo_start;
        self.promo_end = value.data.data.system.promo_end;

      }).catch(function (error) {
        console.log(error);
      });
    },
    changeLan(lan) {
      localStorage.setItem('lan', lan);
      this.$i18n.locale = lan;
      window.location.reload();
    },
    makeToast(variant = null, msg, type) {
      this.msg = msg;
      this.variant = variant;
      this.type = type;
      this.$bvModal.show('modal-message');
    },
  },
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
