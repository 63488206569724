<template>
  <div id="app" :style="{ 'width': computedWidth }">
    <div class="gradient-bg">
      <div class="blur-1"></div>
      <div class="blur-2"></div>
      <div class="blur-3"></div>
    </div>
    <div v-if="$root.isLoading" class="loading">
      <div class="loading-box">
        <div class="loading-effect">
          <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>

        </div>

      </div>


    </div>
    <transition>
      <router-view></router-view>
    </transition>
    <b-modal id="modal-countdown" centered hide-footer hide-header>

      <div class="text-close" style="z-index: 10;" @click="$bvModal.hide('modal-countdown')">
        <i class="fa-solid fa-xmark"></i>
      </div>

      <div class="full-screen" style="max-height: 90vh;">
        <carousel :items="1" :nav="false" v-if="$root.newsList.length > 0">
          <img v-for="item in $root.newsList" :key="item.id" class="rounded" :src="decodeURIComponent(item.public_path)"
            style="display: block;margin: auto;width: 100%;max-height: 90dvh;" @click="$root.goNewsDetails(item)">

        </carousel>
      </div>
    </b-modal>
    <!-- <b-modal id="modal-countdown" centered hide-footer hide-header no-close-on-esc no-close-on-backdrop>
      <div class="modal-img full-screen">
        <img :src="require(`./assets/images/countdown/${days}/${$i18n.locale}.jpg`)" alt="" width="100%">
      </div>
      <div class="float-box">
        <div class="round-box ml-1" style="padding: 7px 8px; width: fit-content;">
          <b-dropdown right variant="link" toggle-class="my-dropdown">

            <template #button-content>
              <img :src="require(`./assets/images/appbar/flag/${$i18n.locale}.png`)" alt="">
            </template>
            <b-dropdown-item href="#" @click="$root.changeLan('zh')">中文</b-dropdown-item>
            <b-dropdown-item href="#" @click="$root.changeLan('en')">English</b-dropdown-item>
            <b-dropdown-item href="#" @click="$root.changeLan('my')">Bahasa Melayu</b-dropdown-item>
            <b-dropdown-item href="#" @click="$root.changeLan('in')">Bahasa Indonesia</b-dropdown-item>
            <b-dropdown-item href="#" @click="$root.changeLan('tm')">ஹிந்தி</b-dropdown-item>
            <b-dropdown-item href="#" @click="$root.changeLan('jp')">日本語</b-dropdown-item>
            <b-dropdown-item href="#" @click="$root.changeLan('kr')">한국인</b-dropdown-item>
            <b-dropdown-item href="#" @click="$root.changeLan('sp')">español</b-dropdown-item>
            <b-dropdown-item href="#" @click="$root.changeLan('th')">แบบไทย</b-dropdown-item>
            <b-dropdown-item href="#" @click="$root.changeLan('vn')">Tiếng Việt</b-dropdown-item>
          </b-dropdown>
        </div>

      </div>
    </b-modal> -->
    <b-modal id="modal-message" centered hide-footer hide-header @hidden="$parent.subtitle = ''">
      <div class="text-close" @click="$bvModal.hide('modal-message')">
        <i class="fa-solid fa-xmark"></i>
      </div>
      <div class="modal-img mx-auto" style="height: 80px; width: 80px;">
        <img v-if="type == 'danger'" src="./assets/images/login/error.png" alt="" width="100%">
        <img v-else src="./assets/images/login/success.png" alt="" width="100%">
      </div>
      <h5 class="my-3" v-html="$parent.msg">
      </h5>
      <h5 v-if="$parent.subtitle.length>0" class="mb-3 text-12" v-html="$parent.subtitle" style="margin-top: -10px;">
      </h5>
      <b-button :variant="type" class="" block @click="$bvModal.hide('modal-message')">
        {{ $t('好的') }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel';
export default {
  name: 'AIBOT',
  data() {
    return {
      success: require('./assets/images/login/success.png'),
      danger: require('./assets/images/login/error.png'),
      screenWidth: 0,
      days: 1
    }
  },
  watch: {
    '$parent.msg': 'checkType'
  },
  computed: {
    type() {
      return this.$parent.type;
    },
    computedWidth: function () {
      return this.screenWidth + 'px';
    }
  },
  components: {
    carousel,
  },
  methods: {
    viewCart() {
      this.$bvModal.hide('modal-message');
      this.$router.push('/web/product/cartList');
    },
    viewRestock() {
      this.$bvModal.hide('modal-message');
      this.$router.push('/web/reseller/prompt');
    },
    checkType() {
      console.log(this.$parent.type);
      switch (this.$parent.type) {
        case 'reminder':
          this.img = this.reminder;
          break;
        case 'cart':
          this.img = this.success;
          break;
        case 'success':
          this.img = this.success;
          break;
        case 'save':
          this.img = this.save;
          break;
        case 'sent':
          this.img = this.sent;
          break;
        case 'invest':
          this.img = this.invest;
          break;
        case 'surrender':
          this.img = this.surrender;
          break;
        case 'danger':
          this.img = this.danger;
          break;
        case 'coming':
          this.img = this.coming;
          break;

        default:
          this.img = this.success;
          break;
      }
    }
  },
  mounted() {
    // this.$root.getNews();
    this.screenWidth = screen.width;
    let date1 = new Date();
    let date2 = new Date("12/15/2023");

    let Difference_In_Time = date2.getTime() - date1.getTime();
    console.log(Difference_In_Time / (1000 * 3600 * 24));
    // this.days =
    //   Math.ceil(Difference_In_Time / (1000 * 3600 * 24));

    if (this.days < 1) {
      this.days = 1;
    }

    // this.$bvModal.show('modal-countdown');

  }
}
</script>

<style>
#app {
  font-family: 'Poppins-Medium' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
